
#introSection {
    background-color: #efefef;
    width: 100%;
    padding-top: 3rem;
    padding-bottom: 3rem;
    margin-bottom: 3rem;
    margin-top: 3rem;
}

.speech-bubble {
    margin: 0 auto;
    position: relative;
    background-color: white;
    border-radius: 32pt;
    padding: 1em;
    padding-bottom: 2rem;
    margin-bottom: 1em;
    max-width: 380pt;
    
    display:flex;
    flex-direction:column;
    align-items:center;
    animation: scaleIn 3s;
}

.social {
    grid: 1fr 1fr;
    width: auto;
}


.social * {
    margin-right: 1rem;
}

/* 
 */

.memoji {
    width: 150pt;
    height: 150pt;
    object-fit: contain;
    animation: scaleIn 3s;
}

#modifyFilters {
    margin: 0 auto;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes scaleIn {
    0% {
        opacity: 0;
        padding: 0;
        transform: scale(0.8);
    }
    100% {
        margin-top: 0;
        opacity: 1;
        transform: scale(1); 
        
    }
}

#filterSection {
    animation: fadeIn 2s;
}
@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.cardContainer {
    padding-bottom: 30pt;
    padding-left: 20pt;
    padding-right: 20pt;
    margin-top: 4rem;
    background-color: #efefef;
    border-radius: 32pt;
    margin-bottom: 20pt;
}

.cardContainer h3 {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.cardSectionContainer {
    max-width: 700pt;
    margin: 0 auto;
    
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-left: 20pt;
    padding-right: 20pt;
}

.cardSectionContainer h3 {
    width: 100%;
    text-align: center;
}

.card {

    background-color: white;
    border-radius: 32pt;
    min-width: 280pt;
    /* height: 180pt; */
    flex: 0 1 24%;
    margin-bottom: 20pt;
    padding: 8pt;
    padding-bottom: 20pt;
    padding-left: 20pt;
    padding-right: 20pt;
    color: black;
    margin-right: '1em';
    margin-left: '1em';
}

.card.skill {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 180pt;
}
.syntaxHighlightEditorContainer {
    font-family: 'Fira code, Fira Mono, monospace';
    width: '100%';
    height: 200pt;
    padding: '8% 8%';
    box-sizing: 'border-box';
    border: '2px solid #ccc';
    border-radius: 16pt;
    background-color: #ddd;
    overflow: scroll;
    margin-bottom: '2em';
}
textarea:focus {
    outline: 0;
}

.card strong {

    padding-right: 1rem;
    text-align: right;
}

a.card:focus {
    outline: none;
}

a.card:not(:disabled):hover {
    box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
}

a.card:not(:disabled):focus {
    box-shadow: rgba(60, 64, 67, .3) 0 1px 1px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

a.card:not(:disabled):active {
    box-shadow: rgba(60, 64, 67, .3) 0 2px 2px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
}

a.card:disabled {
    box-shadow: rgba(60, 64, 67, .3) 0 1px 1px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.card p {
    font-size: 12pt;
}

.card .tag {
    display: inline-block;
}

@media screen and (max-width: 700pt) {
    .card {
        min-width: 100%;
    }
}