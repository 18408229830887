.loader {
    margin: 0 auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 1rem;
    
    
}
.loadingAnimation {
    -webkit-animation: fa-spin 0.75s infinite linear;
        /* WebkitAnimation: 'fa-spin 0.75s infinite linear', */
    animation: fa-spin 0.75s infinite linear;
    /* animation: 'fa-spin 0.75s infinite linear', */
    
    /* padding-top: 20pt; */
    font-size: 40pt;
    height: 40pt;
    width: 40pt;
    color: #54b3d6
}