.App {
  text-align: center;
}

.container {
  margin-left: 1rem;
  margin-right: 1rem;
}

a {
  color: #3493b6;
  text-decoration: none;
}

/*
HANDLE CUSTOM DIALOG STUFF
*/
body.modal-open {
  overflow: hidden; /* see "modal-open" in QueryDialog/index.tsx */
}

/* TYPOGRAPHY STUFF */

h2 {
    font-size: calc(24pt + 1vmin);
}
h3 {
  font-size: calc(20pt + 1vmin);
}
h4 {
  font-size: 18pt;
}
h5 {
  text-align: left;
}
p {
    font-size: calc(13pt + 1vmin);
}
