
nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
    margin-bottom: 4rem;

    background-color: #efefef;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 4;
}


nav ul {
    display: flex;
    list-style: none;
}

nav li {
    text-align: center; 
    margin-left: 1em;
    margin-right: 1em;
}

nav li.active * {
    border: 0px;

}

nav li.active *:before {
    width:100%;
}
 


nav li * {
    padding-top: 4pt;
    padding-bottom: 4pt;
    text-decoration: none;

    color: black;
    cursor: pointer;
}

li a {
    background-image: linear-gradient(
      to right,
      #54b3d6,
      #54b3d6 50%,
      #000 50%
    );
    background-size: 200% 100%;
    background-position: -100%;
    display: inline-block;
    padding: 0 0;
    position: relative;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.3s ease-in-out;
  }
  
  li a:before {
    content: '';
    background: #54b3d6;
    display: block;
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 0;
    height: 3px;
    transition: all 0.3s ease-in-out;
  }
  
  li a:hover {
   background-position: 0;
  }
  
  li a:hover::before {
    width:100%;
  }

   
