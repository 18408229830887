.card h4 {
    margin-bottom: 1rem;
}

.projectImage {
    padding: 1em;
    margin-bottom: 1em;
    background-color: #eee;
    height: 120pt;
    width: 200pt;
    max-width: 80%;
    object-fit: contain;
    animation: scaleIn 3s;
    border-radius: 13pt;
}