

dialog {
    width: auto;
    margin-left: 16pt;
    margin-right: 16pt;
    
    border-radius: 16pt;
    border: none;
}

.buttonContainer {
    margin-top: 2em;
}

.dialogContent {
    padding-right: 20pt;
    padding-left: 20pt;
    padding-bottom: 20pt;
}

dialog::backdrop {
    background: rgba(0, 0, 0, 0.3);
}

dialog h3 {
    margin-bottom: 8pt;
}

dialog p {
    margin-top: 12pt;
}

.buttonContainer {
    display: flex;
    gap: 20pt;
}

#dialogDoneButton {
    width: 33%;
}

#dialogCancelButton {
    width: 33%;
}

#dialogResetButton {
    width: 33%;
}